<template>
  <div id="questionsSection">
    <nav class="navbar-custom-menu">
      <div class="navbar-item">
        <router-link
          :to="{ name: 'Dashboard'}"
          class="navbar-link icon-text is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Passport</span>
        </router-link>
      </div>
      <div class="navbar-item is-hidden-mobile">
        <router-link
          :to="{name: 'QuestionsNew'}"
          class="navbar-link is-arrowless"
          :class="{ 'is-active': showNew }"
        >Ask question</router-link>
      </div>
      <div class="navbar-item is-hidden-mobile">
        <router-link
          :to="{name: 'Questions'}"
          class="navbar-link is-arrowless"
          :class="{ 'is-active': showAll }"
        >All questions</router-link>
      </div>
      <div class="navbar-item is-hidden-mobile">
        <router-link
          :to="{name: 'QuestionsAsked'}"
          class="navbar-link is-arrowless"
          :class="{ 'is-active': showAsked }"
        >My questions</router-link>
      </div>
      <div class="navbar-item is-hidden-mobile">
        <router-link
          :to="{name: 'QuestionsAnswered'}"
          class="navbar-link is-arrowless"
          :class="{ 'is-active': showAnswered }"
        >My Answers</router-link>
      </div>
      <!-- <div class="navbar-item">
        <router-link
          :to="{name: 'QuestionsRelevant'}"
          class="navbar-link is-arrowless is-hidden-mobile"
          :class="{ 'is-active': showRecommended }"
        >Relevant questions</router-link>
      </div> -->
    </nav>
    <hr />

    <transition
      name="slide-fade"
      mode="out-in"
    >
      <div
        :key="$route.name"
        class="contentWrapper"
      >
        <div
          v-show="showAll"
          style="none;"
        >
          <h2 class="title is-3">All questions of the network</h2>
          <p class="subtitle is-5">Maybe you have the answer</p>
        </div>

        <div
          v-show="showAsked"
          style="none;"
        >
          <h2 class="title is-3">My Questions</h2>
          <p class="subtitle is-5">Find your questions and check the answers you got.</p>
        </div>

        <div
          v-show="showAnswered"
          style="display: none;"
        >
          <h2 class="title is-3">My Answers</h2>
          <p class="subtitle is-5">Find the answers you give to the S2M network and see what other experts are saying.</p>
        </div>

        <div
          v-show="showRecommended"
          style="display: none;"
        >
          <h2 class="title is-3">Relevant questions matched to you</h2>
          <p class="subtitle is-5">Here are questions from the S2M network which we think you could answer to.</p>
        </div>

        <!-- <div v-if="!showNew">
          <hr class="separation" />
          <div class="padding has-background-grey-light">
            <label class="checkbox">
              <input
                type="checkbox"
                @change="setShowUnsolvedOnly"
                v-model="pageState.unsolvedOnly"
              />
              Show unsolved questions only
            </label>
          </div>
        </div> -->

        <!-- ASK A QUESTION -->
        <div
          v-if="showNew"
          class="columns"
        >
          <div class="column is-three-fifths">
            <create-question
              @questionAsked="questionAsked"
              :startCurrentStep="2"
              :viewType="'page'"
            ></create-question>
          </div>
          <div class="column is-two-fifths is-hidden-mobile">
            <div class="message is-info has-padding">
              <div class="title is-3">Tips about asking questions</div>
              <Tips :alwaysShowIframe="false" />
            </div>
          </div>
        </div>

        <div
          v-if="!showNew"
          class="questions has-text-left"
        >
          <hr class="separation" />
          <article
            class="questionItemMedia media"
            v-for="question in pageState.questions"
            :key="question.Id"
          >
            <div class="media-left">
              <avatar-image
                class="is-64x64"
                :itemId="question.ProfileId"
                :imageSrc="question.ProfileImage"
                :itemName="question.ProfileName"
              />
            </div>
            <div class="media-content">
              <div class="content">
                <div class="has-text-grey postDetailBar">
                  <span
                    v-show="question.IsSolved"
                    class="has-text-success has-margin-right"
                    style="display: none;"
                  >
                    <i class="fas fa-check-circle"></i> solved
                  </span>
                  <span class="has-margin-right">
                    {{ question.CreatedOn | dateTimeStampToDate | longDateFormat }} -
                    {{ question.CreatedOn | timeFromDateTimeStamp }}
                  </span>
                  {{ question.Answers.length }} answers
                </div>
                <p>
                  <a @click.stop="showProfileDetail(question.ProfileId)">
                    <strong class>{{question.ProfileName}}</strong>
                  </a>:
                  <span
                    class="text-question"
                    :inner-html.prop="question.Text | stripHtml(true) | stringShortner(50, true)"
                  ></span> | <a
                    href="javascript:"
                    class="has-icon"
                    @click="goToQuestion(question)"
                  >
                    <span>View question</span>
                    <span class="icon">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </article>
        </div>
        <transition
          name="fade"
          mode="out-in"
        >
          <ui-loader v-if="isLoading" />
          <div
            v-else-if="!isLoading && !pageState.reachedEnd && !showNew"
            class="p-4 has-text-centered"
          >
            <a
              @click="loadMore"
              class="button is-primary"
            >Load more</a>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
import questionProvider from '../providers/question'
import Tips from '@/components/question/Tips'

export default {
  props: {
    showQuestionsProfileCreated: {
      type: Boolean,
      default: false
    },
    showQuestionsProfileAnswered: {
      type: Boolean,
      default: false
    },
    pageView: {
      type: String,
      default: 'all'
    }
  },

  components: {
    Tips
  },

  data() {
    return {
      isLoading: false,
      bottom: this.$store.state.questionsPageState.bottom,
      cancelToken: axios.CancelToken,
      pageState: this.$store.state.questionsPageState,
      showAll: false,
      showAnswered: false,
      showAsked: false,
      showNew: false,
      showRecommended: false,
      source: null
    }
  },

  watch: {
    '$route.name': function() {
      this.forceRerender()
    }
  },

  created() {
    this.dataLoad(this.pageView)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', null)

    this.$store.commit('setSelectedPublicProfile', {
      profileId: 0,
      view: 'detail'
    })
  },

  methods: {
    loadMore() {
      this.pageState.page = this.pageState.page + 1
      this.getQuestions()
    },

    forceRerender() {
      this.$nextTick().then(() => {
        this.dataLoad(this.pageView)
      })
    },

    dataLoad(pageView) {
      switch (pageView) {
        case 'answered':
          this.setShowAnswered()
          break
        case 'asked':
          this.setShowAsked()
          break
        case 'new':
          this.setShowNew()
          break
        case 'recommended':
          this.setShowRecommended()
          break
        default:
          // All
          this.setShowAll()
          break
      }
    },

    showProfileDetail(profileId) {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: profileId,
        view: 'detail'
      })
    },

    /**
     * Get questions of the selected type
     */
    getQuestions() {
      let self = this
      self.isLoading = false

      /**
       * Get all questions
       */
      if (self.showAll) {
        self.getAllQuestions()
      }

      /**
       * Get only show questions that I have answered
       */
      if (self.showAnswered) {
        self.getQuestionsAnswered()
      }

      /**
       * Get all asked questions by me
       */
      if (self.showAsked) {
        self.getProfileQuestions()
      }

      /**
       * Get all matched questions
       */
      if (self.showRecommended) {
        self.getRecommendedQuestions()
      }
    },

    /**
     * Get all questions
     */
    getAllQuestions() {
      let self = this
      if (!this.isLoading) {
        this.isLoading = true
        if (!this.reachedEnd) {
          questionProvider.methods
            .getAll(
              this.pageState.searchTerm,
              this.pageState.unsolvedOnly,
              this.pageState.page,
              this.pageState.itemsPerPage,
              this.pageState.tag
            )
            .then(response => {
              this.processQuestionResponse(response)
            })
            .finally(() => {
              self.isLoading = false
            })
        }
      }
    },

    /**
     * Get questions I asked
     */
    getProfileQuestions() {
      let self = this
      if (!this.isLoading) {
        this.isLoading = true

        if (!this.reachedEnd) {
          questionProvider.methods
            .getProfileQuestions(
              this.pageState.searchTerm,
              this.pageState.unsolvedOnly,
              this.pageState.page,
              this.pageState.itemsPerPage,
              this.pageState.tag
            )
            .then(response => {
              self.processQuestionResponse(response)
            })
            .finally(() => {
              self.isLoading = false
            })
        }
      }
    },

    /**
     * Get questions I answered
     */
    getQuestionsAnswered() {
      let self = this
      if (!this.isLoading) {
        this.isLoading = true
        if (!this.reachedEnd) {
          questionProvider.methods
            .getQuestionsProfileAnsweredOn(
              this.pageState.unsolvedOnly,
              this.pageState.page,
              this.pageState.itemsPerPage
            )
            .then(response => {
              response.data.Results = response.data
              response.data.MorePages = -1
              self.processQuestionResponse(response)
            })
            .finally(() => {
              self.isLoading = false
            })
        }
      }
    },

    /**
     * Get recommended questions
     */
    getRecommendedQuestions() {
      let self = this
      if (!this.isLoading) {
        this.isLoading = true
        if (!this.reachedEnd) {
          questionProvider.methods
            .getQuestionsProfileMatched(
              this.pageState.unsolvedOnly,
              this.pageState.page,
              this.pageState.itemsPerPage
            )
            .then(response => {
              self.processQuestionResponse(response)
            })
            .finally(() => {
              self.isLoading = false
            })
        }
      }
      this.isLoading = false
    },

    processQuestionResponse(response) {
      let self = this
      if (response.status === 200) {
        let questions = []
        self.pageState.reachedEnd = false
        if ((response.data.MorePages && response.data.MorePages === 0) || response.data.Results.length < this.pageState.itemsPerPage) {
          self.pageState.reachedEnd = true
        }

        if (self.pageState.page > 1) {
          questions = self.pageState.questions
        }

        for (let i = 0; i < response.data.Results.length; i++) {
          questions.push(response.data.Results[i])
        }

        self.pageState.questions = questions

        this.$store.commit('setQuestionsPageState', this.pageState)
      }
    },

    filterQuestions(tag) {
      this.page = 1
      this.reachedEnd = false
      this.tag = tag
      this.getQuestions()
    },

    clearTagFilter() {
      this.page = 1
      this.reachedEnd = false
      this.tag = ''
      this.getQuestions()
    },

    goToQuestion(question) {
      this.$store.commit('setSelectedQuestion', question)
      this.$router.push({
        name: `QuestionDetail`,
        params: { questionId: question.Id }
      })
    },

    updateQuestionSolved(questionId) {
      let self = this
      let questions = self.pageState.questions

      let questionIndex = questions.findIndex(q => q.Id === questionId)
      if (questionIndex > -1) {
        questions[questionIndex].IsSolved = true

        self.pageState.questions = questions

        self.$store.commit('setQuestionsPageState', self.pageState)
      }
    },

    updateAnswerScore(questionId, answerId, value) {
      let self = this
      let questions = self.pageState.questions

      let questionIndex = questions.findIndex(q => q.Id === questionId)
      if (questionIndex > -1) {
        let answerIndex = questions[questionIndex].Answers.findIndex(
          a => a.Id === answerId
        )
        if (answerIndex > -1) {
          let currentScore =
            questions[questionIndex].Answers[answerIndex].VoteScore

          questions[questionIndex].Answers[answerIndex].VoteScore =
            currentScore + value

          self.pageState.questions = questions

          self.$store.commit('setQuestionsPageState', self.pageState)
        }
      }
    },

    questionAsked(question) {
      this.setShowAsked()
    },

    setShowUnsolvedOnly() {
      this.pageState.questions = []
      this.pageState.page = 1
      this.pageState.bottom = false
      this.pageState.reachedEnd = false
      this.getQuestions()
    },

    /**
     * Show all questions
     */
    setShowAll() {
      questionProvider.methods.cancelGetQuestions()
      questionProvider.methods.cancelGetQuestionsProfileMatchedCancelToken()
      questionProvider.methods.cancelGetQuestionsProfileAnsweredOn()
      this.setVisibleItem('all')
      // this.$router.push({ name: 'Questions' })
    },

    /**
     * Only show questions that I have answered
     */
    setShowAnswered() {
      questionProvider.methods.cancelGetQuestions()
      questionProvider.methods.cancelGetQuestionsProfileMatchedCancelToken()
      questionProvider.methods.cancelGetAllQuestions()
      this.setVisibleItem('answered')
      // this.$router.push({ name: 'QuestionsAnswered' })
    },

    /**
     * Show all asked questions by me
     */
    setShowAsked() {
      questionProvider.methods.cancelGetAllQuestions()
      questionProvider.methods.cancelGetQuestionsProfileMatchedCancelToken()
      questionProvider.methods.cancelGetQuestionsProfileAnsweredOn()
      this.setVisibleItem('asked')
      // this.$router.push({ name: 'QuestionsAsked' })
    },

    /**
     * Show all matched questions
     */
    setShowRecommended() {
      questionProvider.methods.cancelGetAllQuestions()
      questionProvider.methods.cancelGetQuestions()
      questionProvider.methods.cancelGetQuestionsProfileAnsweredOn()
      this.setVisibleItem('recommended')
      // this.$router.push({ name: 'QuestionsRelevant' })
    },

    /**
     * Ask a question
     */
    setShowNew() {
      questionProvider.methods.cancelGetAllQuestions()
      questionProvider.methods.cancelGetQuestions()
      questionProvider.methods.cancelGetQuestionsProfileMatchedCancelToken()
      questionProvider.methods.cancelGetQuestionsProfileAnsweredOn()
      this.setVisibleItem('new')
      // this.$router.push({ name: 'QuestionsNew' })
    },

    setVisibleItem(item) {
      let self = this
      this.showAll = item === 'all'
      this.showAnswered = item === 'answered'
      this.showAsked = item === 'asked'
      this.showNew = item === 'new'
      this.showRecommended = item === 'recommended'

      if (this.pageState.visibleItem && this.pageState.visibleItem !== item) {
        this.pageState.questions = []
        this.pageState.page = 1
        this.pageState.bottom = false
        this.pageState.reachedEnd = false
      }

      this.pageState.visibleItem = item

      this.$store.commit('setQuestionsPageState', this.pageState)
      let t = setTimeout(() => {
        self.getQuestions()
        clearTimeout(t)
      }, 50)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";
#questionsSection {
  .postDetailBar {
    font-size: 90%;
    margin-bottom: 5px;
  }
  // .navbar {
  //   background-color: $cyan;
  // }
  // img {
  //   background-color: $grey-light;
  // }

  // a.is-link {
  //   color: $cyan;
  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }
}
.media-content {
  width: 100%;

  .text-question {
    word-break: break-word;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 550px) {
  .image {
    &.is-64x64 {
      height: 42px;
      width: 42px;
    }
  }
}
</style>
